
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElForm,
  ElFormItem,
  ElInput,
  ElSelect,
  ElOption,
  ElDatePicker,
} from "element-plus";

// Services
import {getTranslation} from "@/services/app/translation";
import {getMonthFirstDay, getMonthLastDay, getDateStartTime, getDateEndTime} from "@/services/app/date";

@Options({
  props: [
    "partners",
  ],
  components: {
    ElForm,
    ElFormItem,
    ElInput,
    ElSelect,
    ElOption,
    ElDatePicker,
  },
})
export default class AppAdministratorReportsIncomingInvoicesListFormFilterIndexVue extends Vue {
  isLoading = false;

  formData = {
    partner_id: "*",
    status: "*",
    period: [
      getMonthFirstDay(),
      getMonthLastDay(),
    ],
    sort: ["invoice-number-in-year"],
  };

  get translation(): any {
    return getTranslation([
      "allPartners",
      "allStatuses",
      "choose",
      "closed",
      "date",
      "filter",
      "invoiceNumberInYear",
      "partner",
      "period",
      "status",
      "sort",
      "opened",
    ]);
  }

  $refs!: {
    formComponent: HTMLFormElement;
  };

  async submitForm(): Promise<void> {
    this.$emit("getIncomingInvoicesReport", this.formData.partner_id, this.formData.status, this.formData.period, this.formData.sort);
  }

  setPeriod(period: any): void {
    this.formData.period = [
      getDateStartTime(period[0]),
      getDateEndTime(period[1]),
    ];
    this.submitForm();
  }
}
