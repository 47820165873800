
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AdministratorNavigationVue from "@/components/administrator/navigation.vue";
import AdministratorNavigationReportsVue from "@/components/administrator/navigation-reports.vue";
import AppAdministratorReportsIncomingInvoicesListIndexVue from "@/views/app/administrator/reports/incoming-invoices/list/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    AdministratorNavigationVue,
    AdministratorNavigationReportsVue,
    AppAdministratorReportsIncomingInvoicesListIndexVue,
  },
})
export default class AppAdministratorReportsIncomingInvoicesIndexVue extends Vue {}
