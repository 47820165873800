import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4459b50a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-administrator-reports-incoming-invoices-list" }
const _hoisted_2 = { class: "box" }
const _hoisted_3 = { class: "box__headline" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = {
  key: 0,
  class: "action"
}
const _hoisted_6 = { class: "box__filter" }
const _hoisted_7 = { class: "pdf" }
const _hoisted_8 = { class: "pdf-content" }
const _hoisted_9 = { class: "page-footer" }
const _hoisted_10 = { class: "information" }
const _hoisted_11 = { class: "statistic" }
const _hoisted_12 = {
  key: 0,
  class: "html2pdf__page-break"
}
const _hoisted_13 = {
  key: 0,
  class: "box__message"
}
const _hoisted_14 = {
  key: 1,
  class: "box__loading"
}
const _hoisted_15 = {
  key: 0,
  class: "progress"
}
const _hoisted_16 = { class: "progress__counter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Printer = _resolveComponent("Printer")!
  const _component_AppAdministratorReportsIncomingInvoicesListFormFilterIndexVue = _resolveComponent("AppAdministratorReportsIncomingInvoicesListFormFilterIndexVue")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_vue3_html2pdf = _resolveComponent("vue3-html2pdf")!
  const _component_LoadingIndexVue = _resolveComponent("LoadingIndexVue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.translation.report), 1),
        (_ctx.isDataLoaded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("a", {
                href: "#",
                onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.getReport && _ctx.getReport(...args)), ["prevent"]))
              }, [
                _createVNode(_component_Printer)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_AppAdministratorReportsIncomingInvoicesListFormFilterIndexVue, {
          partners: _ctx.partners,
          onGetIncomingInvoicesReport: _ctx.getIncomingInvoicesReport
        }, null, 8, ["partners", "onGetIncomingInvoicesReport"])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["box__list", {'d-none': !_ctx.isDataLoaded}])
      }, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_vue3_html2pdf, {
            "show-layout": false,
            "float-layout": false,
            "enable-download": true,
            "preview-modal": false,
            "manual-pagination": true,
            filename: `${_ctx.translation.report} - ${_ctx.getDateFormat(_ctx.period[0])} - ${_ctx.getDateFormat(_ctx.period[1])}`,
            "pdf-quality": 2,
            "pdf-format": "a4",
            "pdf-orientation": "landscape",
            "pdf-content-width": "1199px",
            ref: "html2Pdf",
            onProgress: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setProgress($event)))
          }, {
            "pdf-content": _withCtx(() => [
              _createElementVNode("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.numberOfPages, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "page",
                    key: item
                  }, [
                    _createVNode(_component_el_table, {
                      data: _ctx.incomingInvoices.slice((item - 1) * 18, (item * 18) - 1),
                      style: {"width":"100%"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_table_column, {
                          label: _ctx.translation.invoiceNumberInYearURA,
                          width: "100"
                        }, {
                          default: _withCtx((scope) => [
                            _createTextVNode(_toDisplayString(_ctx.getInvoiceNumberInYear(scope.row.content.invoiceNumberInYear, scope.row.content.year)) + " " + _toDisplayString(_ctx.getInformation(scope.row.invoice_type.content.title)), 1)
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        _createVNode(_component_el_table_column, {
                          label: _ctx.translation.invoiceNumber,
                          width: "160"
                        }, {
                          default: _withCtx((scope) => [
                            _createTextVNode(_toDisplayString(_ctx.getInformation(scope.row.content.invoiceNumber)), 1)
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        _createVNode(_component_el_table_column, {
                          label: _ctx.translation.partner,
                          width: "200"
                        }, {
                          default: _withCtx((scope) => [
                            _createTextVNode(_toDisplayString(_ctx.getInformation(scope.row.partner.content.title).slice(0, 20)), 1)
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        _createVNode(_component_el_table_column, {
                          label: _ctx.translation.paymentDescription,
                          width: "200"
                        }, {
                          default: _withCtx((scope) => [
                            _createTextVNode(_toDisplayString(_ctx.getInformation(scope.row.content.paymentDescription).slice(0, 20)), 1)
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        _createVNode(_component_el_table_column, {
                          label: _ctx.translation.date,
                          width: "80"
                        }, {
                          default: _withCtx((scope) => [
                            _createTextVNode(_toDisplayString(_ctx.getDateFormat(scope.row.content.date)), 1)
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        _createVNode(_component_el_table_column, {
                          label: _ctx.translation.dateOfExchangeRateShort,
                          width: "80"
                        }, {
                          default: _withCtx((scope) => [
                            _createTextVNode(_toDisplayString(_ctx.getDateFormat(scope.row.content.dateOfExchangeRate)), 1)
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        _createVNode(_component_el_table_column, {
                          label: _ctx.translation.amountWithVAT,
                          width: "90"
                        }, {
                          default: _withCtx((scope) => [
                            _createTextVNode(_toDisplayString(_ctx.getCurrencyFormatEUR(scope.row.content.amount.eur)), 1)
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        _createVNode(_component_el_table_column, {
                          label: _ctx.translation.paid,
                          width: "90"
                        }, {
                          default: _withCtx((scope) => [
                            _createTextVNode(_toDisplayString(_ctx.getCurrencyFormatEUR(scope.row.content.paidAmount.eur)), 1)
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        _createVNode(_component_el_table_column, {
                          label: _ctx.translation.status,
                          width: "60"
                        }, {
                          default: _withCtx((scope) => [
                            _createElementVNode("span", {
                              class: _normalizeClass(`status--${_ctx.getInvoiceStatusClass(scope.row.content.status)}`)
                            }, _toDisplayString(_ctx.getInvoiceStatusShort(scope.row.content.status)), 3)
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 2
                    }, 1032, ["data"]),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.translation.report) + ": " + _toDisplayString(_ctx.getDateFormat(_ctx.period[0])) + " - " + _toDisplayString(_ctx.getDateFormat(_ctx.period[1])) + " · " + _toDisplayString(_ctx.translation.page) + " " + _toDisplayString(item) + " " + _toDisplayString(_ctx.translation.of) + " " + _toDisplayString(_ctx.numberOfPages), 1),
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("span", null, " Iznos: " + _toDisplayString(_ctx.getCurrencyFormatEUR(_ctx.statistic.amount)), 1),
                        _createElementVNode("span", null, " Uplaćeno: " + _toDisplayString(_ctx.getCurrencyFormatEUR(_ctx.statistic.paidAmount)), 1),
                        _createElementVNode("span", null, " Razlika: " + _toDisplayString(_ctx.getCurrencyFormatEUR(_ctx.statistic.difference)), 1)
                      ])
                    ]),
                    (item < _ctx.numberOfPages)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ])
            ]),
            _: 1
          }, 8, ["filename"])
        ])
      ], 2),
      (_ctx.isDataLoadedAndEmpty)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.translation.noData), 1))
        : _createCommentVNode("", true),
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            _createVNode(_component_LoadingIndexVue)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isLoadingProgress)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.progress) + "% ", 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}